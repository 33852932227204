<template>
  <div v-if="$ability.can('read', this.$route.meta.resource)">
    <modal-detail
      :store-module-name="STORE_MODULE_NAME"
      :is-modal-active.sync="isModalDetailActive"
      :show.sync="show"
      :data="modalDetailData"
    />
    <b-overlay
      :show="show"
      rounded="sm"
    >
      <b-card
        no-body
        class="mb-2"
      >
        <div class="m-2">
          <b-row>
            <b-col
              cols="12"
              class="b-col-custom"
            >
              <div class="d-flex align-items-center justify-content-end">
                <b-form-input
                  v-model="searchQuery"
                  class="d-inline-block mr-1"
                  :placeholder="$t('Search') + '...'"
                  @input="typing($event)"
                />
              </div>
            </b-col>
          </b-row>
        </div>
      </b-card>
      <b-card
        no-body
        class="mb-0"
      >
        <div class="m-2">
          <b-row>
            <b-col cols="1">
              {{ $t('Background') }}
            </b-col>
            <b-col cols="1">
              {{ $t('ID') }}
            </b-col>
            <b-col cols="3">
              {{ $t('Name') }}
            </b-col>
            <!-- <b-col cols="1">{{ $t('Description') }}</b-col> -->
            <b-col cols="2">
              <div class="d-flex align-items-center justify-content-center">
                {{ $t('Template') }}
              </div>
            </b-col>
            <b-col cols="1">
              <div class="d-flex align-items-center justify-content-center">
                {{ $t('Link') }}
              </div>
            </b-col>
            <b-col cols="1">
              <div class="d-flex align-items-center justify-content-end">
                {{ $t('Hide Title') }}
              </div>
            </b-col>
            <b-col cols="1">
              <div class="d-flex align-items-center justify-content-end">
                {{ $t('Hide') }}
              </div>
            </b-col>
            <b-col cols="2">
              <div class="d-flex align-items-center justify-content-end">
                {{ $t('Action') }}
              </div>
            </b-col>
          </b-row>
          <draggable
            v-model="myArray"
            @change="updateOrder"
          >
            <div
              v-for="(element,index) in myArray"
              :key="index"
              class="mt-1"
            >
              <b-row>
                <b-col cols="1">
                  <div class="d-flex align-items-center justify-content-start">
                    <b-form-file
                      :ref="`fileInput_${index}`"
                      accept=".webp, .jpeg, .jpg, .png, .gif"
                      :hidden="true"
                      plain
                      @change="inputImageRenderer($event, index)"
                    />
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      size="sm"
                      class="d-flex"
                      @click="addFileInput(index)"
                    >
                      <feather-icon icon="CameraIcon" />
                    </b-button>
                  </div>
                </b-col>
                <b-col cols="1">
                  <span class="align-middle">{{ element.orderBy }}</span>
                </b-col>
                <b-col cols="3">
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="success"
                    size="sm"
                    class="d-flex"
                    @click.stop="modalDetail(element)"
                  >{{ showFromCurrentLanguage(element.name) }}</b-button>
                </b-col>
                <!-- <b-col cols="1">
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="warning"
                    size="sm"
                    class="d-flex"
                  >{{ showFromCurrentLanguage(element.description) }}</b-button>
                </b-col>-->
                <b-col cols="2">
                  <v-select
                    v-model="element.typeTemplate"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="OptionTemplate"
                    :reduce="(OptionTemplate) => OptionTemplate.value"
                    :clearable="false"
                    class="select-size-sm"
                    label="name"
                    @input="update(element)"
                  />
                </b-col>
                <b-col cols="1">
                  <v-select
                    v-model="element.typeLink"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="OptionLink"
                    :reduce="(OptionLink) => OptionLink.value"
                    :clearable="false"
                    class="select-size-sm"
                    label="name"
                    @input="update(element)"
                  />
                </b-col>
                <b-col cols="1">
                  <div class="d-flex align-items-center justify-content-end">
                    <b-form-checkbox
                      v-model="element.hideTitle"
                      class="custom-control-primary"
                      name="check-button"
                      switch
                      @change="update(element)"
                    />
                  </div>
                </b-col>
                <b-col cols="1">
                  <div class="d-flex align-items-center justify-content-end">
                    <b-form-checkbox
                      v-model="element.hide"
                      class="custom-control-primary"
                      name="check-button"
                      switch
                      @change="update(element)"
                    />
                  </div>
                </b-col>
                <b-col cols="2">
                  <div class="d-flex align-items-center justify-content-end">
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      size="sm"
                      class="d-flex mr-1"
                      @click.stop="view(element)"
                    >
                      <feather-icon icon="EyeIcon" />
                    </b-button>
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="danger"
                      size="sm"
                      class="d-flex"
                      @click.stop="remove(element)"
                    >
                      <feather-icon icon="TrashIcon" />
                      <!-- <span class="align-middle">Add Users</span> -->
                    </b-button>
                  </div>
                </b-col>
              </b-row>
            </div>
          </draggable>
          <hr>
          <b-row class="mt-2">
            <b-col cols="1">
              <b-form-group :label="$t('ID')">
                {{ myArray.length+1 }}
              </b-form-group>
            </b-col>
            <b-col cols="2">
              <b-form-group
                v-for="(item , index) in language"
                :key="`${index}_name`"
                :label="$t(item.name)"
                :label-for="item.name"
              >
                <b-form-input
                  v-if="name[index]"
                  :id="item.name"
                  :name="item.name"
                  :value="name[index]['value']"
                  :placeholder="$t(item.name)"
                  @input="inputLanguage($event,item.lang ,index,'name')"
                />
              </b-form-group>
            </b-col>
            <b-col cols="2">
              <b-form-group
                v-for="(item , index) in language"
                :key="`${index}_des`"
                :label="$t(item.name)"
                :label-for="item.name"
              >
                <b-form-input
                  v-if="description[index]"
                  :id="item.name"
                  :name="item.name"
                  :value="description[index]['value']"
                  :placeholder="$t(item.name)"
                  @input="inputLanguage($event,item.lang ,index,'description')"
                />
              </b-form-group>
            </b-col>
            <b-col cols="2">
              <b-form-group :label="$t('Template')">
                <v-select
                  v-model="typeTemplate"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="OptionTemplate"
                  :reduce="(OptionTemplate) => OptionTemplate.value"
                  :clearable="false"
                  class="select-size-sm"
                  label="name"
                />
              </b-form-group>
            </b-col>
            <b-col cols="2">
              <b-form-group :label="$t('Link')">
                <v-select
                  v-model="typeLink"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="OptionLink"
                  :reduce="(OptionLink) => OptionLink.value"
                  :clearable="false"
                  class="select-size-sm"
                  label="name"
                />
              </b-form-group>
            </b-col>
            <b-col cols="1">
              <div class="d-flex align-items-center justify-content-end" />
            </b-col>
            <b-col cols="2">
              <div class="d-flex align-items-center justify-content-end mb-1">
                <span>{{ $t('Action') }}</span>
              </div>
              <div class="d-flex align-items-center justify-content-end">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="success"
                  size="sm"
                  class="d-flex"
                  @click="add"
                >
                  <feather-icon icon="PlusIcon" />
                  <!-- <span class="align-middle">Add Users</span> -->
                </b-button>
              </div>
            </b-col>
          </b-row>
        </div>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>

import {
  BFormFile,
  BCard,
  BRow,
  BCol,
  BImg,
  BAvatar,
  BFormInput,
  BFormGroup,
  BFormCheckbox,
  BButton,
  BTable,
  BPagination,
  BDropdown,
  BOverlay,
  BCardText,
  BDropdownItem,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import perPageOptions from '@/perPageOptions'
// import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import { ref } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import storeModule from '@/store/services/eCommerce/promote'
import debounce from 'debounce'
import draggable from 'vuedraggable'
import ModalDetail from './components/ModalDetail.vue'

const STORE_MODULE_NAME = 'promote'

export default {
  components: {
    BFormFile,
    BCard,
    BRow,
    BCol,
    // eslint-disable-next-line vue/no-unused-components
    BImg,
    // eslint-disable-next-line vue/no-unused-components
    BAvatar,
    BFormInput,
    BFormGroup,
    BFormCheckbox,
    BButton,
    BTable,
    BPagination,
    BDropdown,
    BDropdownItem,
    BOverlay,
    BCardText,

    // BFormFile,
    // BBadge,
    // BLink,
    // BMedia,
    // BAvatar,
    // eslint-disable-next-line vue/no-unused-components
    vSelect,
    draggable,
    ModalDetail,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      avatarText,
      currentPage: 1,
      searchQuery: '',
      perPage: localStorage.getItem('itemsPerPage')
        ? localStorage.getItem('itemsPerPage')
        : 30,
      show: false,
      warehouse: '',
      isModalDetailActive: false,
      modalDetailData: {},
      myArray: [],
      background: '',
      name: [],
      description: [],
      typeTemplate: '',
      typeLink: '',
      perPageOptions,
    }
  },
  computed: {
    OptionTemplate() {
      return [
        { name: this.$t('Row'), value: 'row' },
        { name: this.$t('Slide'), value: 'slide' },
        { name: this.$t('Background'), value: 'background' },
        { name: this.$t('Group'), value: 'group' },
      ]
    },
    OptionLink() {
      return [
        { name: this.$t('Product'), value: 'product' },
        { name: this.$t('Coupon'), value: 'coupon' },
        { name: this.$t('Coupon Category'), value: 'couponCategory' },
        { name: this.$t('Link'), value: 'link' },
        { name: this.$t('HTML'), value: 'html' },
        { name: this.$t('Action Menu'), value: 'actionMenu' },
      ]
    },
    respData() {
      return store.state[STORE_MODULE_NAME].respData != null
        ? store.state[STORE_MODULE_NAME].respData.data
        : []
    },
    queriedItems() {
      return store.state[STORE_MODULE_NAME].respData != null
        ? store.state[STORE_MODULE_NAME].respData.max
        : 0
    },
    language() {
      return store.state.appConfig.systemConfig
        ? store.state.appConfig.systemConfig.language
        : []
    },
  },
  created() {
    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, storeModule)
    this.initLanguage()

    this.get()
    this.typing = debounce(this.get, 500)
  },
  destroyed() {
    if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
  },
  setup() {
    return { STORE_MODULE_NAME }
  },
  methods: {
    modalDetail(item) {
      this.modalDetailData = item
      this.isModalDetailActive = true
    },
    view(data) {
      this.$router.push({
        name: 'e-commerce-promote-view',
        params: { id: data._id },
      })
    },
    initLanguage() {
      if (this.name.length !== this.language.length) {
        for (let index = 0; index < this.language.length; index += 1) {
          const indexLanguage = this.name.findIndex(
            element => element.lang === this.language[index].lang,
          )

          if (indexLanguage < 0) {
            this.name.push({
              lang: this.language[index].lang, // th , en
              value: '',
            })
          }
        }
      }
      if (this.description.length !== this.language.length) {
        for (let index = 0; index < this.language.length; index += 1) {
          const indexLanguage = this.description.findIndex(
            element => element.lang === this.language[index].lang,
          )

          if (indexLanguage < 0) {
            this.description.push({
              lang: this.language[index].lang, // th , en
              value: '',
            })
          }
        }
      }
    },
    inputImageRenderer(input, index) {
      // eslint-disable-next-line prefer-destructuring
      if (input.target.files && input.target.files[0]) {
        const reader = new FileReader()
        reader.onload = e => {
          this.myArray[index].background = e.target.result
          this.update(this.myArray[index])
          // this.images[index] = e.target.result
        }
        reader.readAsDataURL(input.target.files[0])
      }
    },
    addFileInput(index) {
      // Add a new null element to the fileInputs array
      this.$refs[`fileInput_${index}`][0].$el.click()
    },
    updateOrder() {
      store
        .dispatch(`${STORE_MODULE_NAME}/updateOrder`, { list: this.myArray })
        .then(result => {
          // this.showToast('success', 'bottom-right')
          this.get()
        })
        .catch(error => {
          console.log('fetchUsers Error : ', error)
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })

          // this.showToast('danger', 'bottom-right')
        })
    },
    // switchChange(id, status) {
    //   console.log(id, status);
    // },
    update(data) {
      store
        .dispatch(`${STORE_MODULE_NAME}/update`, data)
        .then(result => {
          // this.showToast('success', 'bottom-right')
          this.get()
        })
        .catch(error => {
          console.log('fetchUsers Error : ', error)
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })

          // this.showToast('danger', 'bottom-right')
        })
    },
    showFromCurrentLanguage(data) {
      if (data) {
        const indexLang = data.findIndex(e => e.lang === this.$i18n.locale)
        if (indexLang > -1) {
          return data[indexLang].value
        }
        return ''
      }
      return ''
    },
    get() {
      const obj = {
        currentPage: this.currentPage,
        pageSize: this.perPage,
        searchQuery: this.searchQuery,
      }

      this.show = true
      store
        .dispatch(`${STORE_MODULE_NAME}/get`, obj)
        // eslint-disable-next-line no-unused-vars
        .then(result => {
          this.show = false
          this.myArray = result.data.data
          // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
          // eslint-disable-next-line no-unused-vars
        })
        .catch(error => {
          this.show = false
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
          // console.log('fetchUsers Error : ', error)
          // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
        })
    },
    add() {
      const obj = {
        background: this.background,
        name: this.name,
        description: this.description,
        typeTemplate: this.typeTemplate,
        typeLink: this.typeLink,
      }
      store
        .dispatch(`${STORE_MODULE_NAME}/add`, obj)
        .then(result => {
          // this.showToast('success', 'bottom-right')
          this.background = ''
          this.name = []
          this.description = []
          this.typeTemplate = ''
          this.typeLink = ''

          this.initLanguage()
        })
        .catch(error => {
          console.log('fetchUsers Error : ', error)
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })

          // this.showToast('danger', 'bottom-right')
        })
    },
    remove(item) {
      this.$bvModal
        .msgBoxConfirm(`${this.$t('Do you want to delete')}?`, {
          title: this.$t('Please Confirm'),
          cancelVariant: 'outline-secondary',
          okVariant: 'danger',
          okTitle: this.$t('Yes'),
          cancelTitle: this.$t('No'),
          centered: true,
        })
        .then(value => {
          if (value === true) {
            this.show = true
            store
              .dispatch(`${STORE_MODULE_NAME}/delete`, { id: item._id })
              .then(result => {
                this.show = false

                // this.showToast('success', 'bottom-right')
              })
              .catch(error => {
                console.log('fetchUsers Error : ', error)
                this.show = false

                this.$toast({
                  component: ToastificationContent,
                  position: 'bottom-right',
                  props: {
                    title: this.$t('Error'),
                    icon: 'ErrorIcon',
                    variant: 'danger',
                    text: this.$t(error.response.data.message),
                  },
                })

                // this.showToast('danger', 'bottom-right')
              })
          }
          //  else {
          //   this.showToast(
          //     'warning',
          //     'top-right',
          //     `${this.$t('DELETE_CANCEL')}!`,
          //     'AlertCircleIcon',
          //     this.$t('DELETE_CANCEL'),
          //   )
          // }
        })
    },
    inputLanguage(input, lang, index, type) {
      if (type === 'name') {
        if (this.name[index].lang === lang) {
          this.name[index].value = input
        }
      } else if (type === 'description') {
        if (this.description[index].lang === lang) {
          this.description[index].value = input
        }
      }
    },
    showToast(variant, position, text, icon, title) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title,
            icon,
            text,
            // icon: 'InfoIcon',
            // text: 'I do not think that word means what you think it means.',
            variant,
          },
        },
        {
          position,
        },
      )
    },
  },
}
</script>

  <style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

.v-select-custom {
  display: inline-block;
  width: 50;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.b-col-custom {
  margin-top: 0.5rem;
}
</style>
