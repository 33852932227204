<template>
  <div>
    <b-modal
      :visible="isModalActive"
      :title="
          `${$t('Detail')}`
        "
      ok-title="Accept"
      size="lg"
      @change="val => $emit('update:is-modal-active', val)"
    >
      <b-overlay :show="dataLoading" rounded="sm">
        <validation-observer ref="formData">
          <b-form class="p-2">
            <b-row class="mb-2">
              <b-col cols="12">
                <b-form-group :label="$t('Background')" label-for="register-background">
                  <!-- media -->
                  <b-img
                          v-if="background"
                          ref="previewEl"
                          rounded
                          :src="background"
                          height="80"
                          @click="viewImage(background)"
                        />
                        <b-img
                          v-else
                          ref="previewEl"
                          :src="require('@/assets/images/blank/no_image.png')"
                          rounded
                          height="80"
                        />
                  <!--/ media -->
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group :label="$t('Name')" label-for="register-name">
                  <b-row>
                    <b-col v-for="(item, index) in language" :key="`${index}_name`" cols="12">
                      <!-- firstName -->
                      <!-- firstName -->
                      <b-form-group :label="$t(item.name)" :label-for="item.name">
                        <validation-provider
                          #default="{ errors }"
                          :name="item.name"
                          :vid="item.name"
                        >
                          <b-form-input
                            v-if="name[index]"
                            :id="item.name"
                            :name="item.name"
                            :value="name[index]['value']"
                            :state="errors.length > 0 ? false : null"
                            :placeholder="$t(item.name)"
                            @input="inputLanguage($event, item.lang, index, 'name')"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-form-group>
              </b-col>

              <b-col cols="12">
                <b-form-group :label="$t('Description')" label-for="description">
                  <b-row>
                    <b-col
                      v-for="(item, index) in language"
                      :key="`${index}_description`"
                      cols="12"
                    >
                      <b-form-group :label="$t(item.name)" :label-for="item.name">
                        <validation-provider
                          #default="{ errors }"
                          :name="item.name"
                          :vid="item.name"
                        >
                          <b-form-input
                            v-if="description[index]"
                            :id="item.name"
                            :name="item.name"
                            :value="description[index]['value']"
                            :state="errors.length > 0 ? false : null"
                            :placeholder="$t(item.name)"
                            @input="inputLanguage($event, item.lang, index, 'description')"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-form-group>
              </b-col>

              <b-col cols="12">
                <b-form-group :label="$t('Link')" label-for="register-link-url">
                  <validation-provider #default="{ errors }" name="Link URL" vid="link-url" rules>
                    <b-form-input
                      id="register-link-url"
                      v-model="link"
                      name="register-link-url"
                      :state="errors.length > 0 ? false : null"
                      :placeholder="$t('Link')"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-overlay>

      <template #modal-footer>
        <div class="w-100">
          <div class="float-right">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="success"
              size="sm"
              class="mr-2"
              @click="validationForm"
            >
              {{ $t('Save') }}
              <feather-icon icon="SaveIcon" size="12" />
            </b-button>
          </div>
        </div>
      </template>
    </b-modal>
    <b-modal v-model="showImage" id="modal-center" centered hide-footer size="lg">
      <b-img :src="imageView != '' ? imageView : require('@/assets/images/blank/no_image.png')" style="width: 100%;"
        :alt="'Chat'" />

    </b-modal>
  </div>
</template>
  
  <script>
import {
  BForm,
  BImg,
  BModal,
  BRow,
  BCol,
  BTabs,
  BTab,
  BTable,
  BCardText,
  BFormCheckbox,
  BButton,
  BFormInput,
  BFormGroup,
  BOverlay,
  BPagination
} from "bootstrap-vue";
import { required } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import { ValidationProvider, ValidationObserver } from "vee-validate";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import store from "@/store";

export default {
  components: {
    BForm,
    BImg,

    // eslint-disable-next-line vue/no-unused-components
    vSelect,
    BModal,
    // eslint-disable-next-line vue/no-unused-components
    BRow,
    // eslint-disable-next-line vue/no-unused-components
    BCol,
    BTabs,
    BTab,
    BTable,
    // eslint-disable-next-line vue/no-unused-components
    BCardText,
    // eslint-disable-next-line vue/no-unused-components
    BFormCheckbox,
    // eslint-disable-next-line vue/no-unused-components
    BButton,
    BFormInput,
    // eslint-disable-next-line vue/no-unused-components
    BFormGroup,
    BOverlay,
    BPagination,
    ValidationProvider,
    ValidationObserver
  },
  directives: {
    Ripple
  },
  mixins: [togglePasswordVisibility],
  model: {
    prop: "isModalActive",
    event: "update:is-modal-active"
  },
  props: {
    storeModuleName: {
      type: String,
      required: true
    },
    isModalActive: {
      type: Boolean,
      required: true
    },
    data: {
      type: Object,
      default: () => {}
    },
    show: {
      type: Boolean,
      required: false
    }
  },
  data() {
    return {
      currentPage: 1,
      searchQuery: "",
      perPage: localStorage.getItem("itemsPerPage")
        ? localStorage.getItem("itemsPerPage")
        : 30,
      dataId: null,
      dataLoading: false,
      showImage: false,
      imageView: "",
      name: [],
      description: [],
      background: "",
      typeTemplate: "",
      typeLink: "",
      hideTitle: "",
      required
    };
  },
  computed: {
    respData() {
      return store.state[this.storeModuleName].respDataStockInWarehouse != null
        ? store.state[this.storeModuleName].respDataStockInWarehouse.data
        : [];
    },
    queriedItems() {
      return store.state[this.storeModuleName].respDataStockInWarehouse != null
        ? store.state[this.storeModuleName].respDataStockInWarehouse.max
        : 0;
    },
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    tableColumns() {
      return [
        { key: "name", label: this.$t("Name"), sortable: false },
        { key: "stock", label: this.$t("Stock"), sortable: false },
        { key: "action", label: "", sortable: false }
      ];
    },
    language() {
      return store.state.appConfig.systemConfig
        ? store.state.appConfig.systemConfig.language
        : [];
    }
  },
  watch: {
    isModalActive(val) {
      if (val) {
        if (Object.entries(this.data).length === 0) {
          // this.$refs.formData.reset()
          this.initValues();
          if (this.name.length !== this.language.length) {
            for (let index = 0; index < this.language.length; index += 1) {
              const indexLanguage = this.name.findIndex(
                element => element.lang === this.language[index].lang
              );
              console.log(indexLanguage);

              if (indexLanguage < 0) {
                this.name.push({
                  lang: this.language[index].lang, // th , en
                  value: ""
                });
              }
            }
          }
          if (this.description.length !== this.language.length) {
            for (let index = 0; index < this.language.length; index += 1) {
              const indexLanguage = this.description.findIndex(
                element => element.lang === this.language[index].lang
              );
              console.log(indexLanguage);

              if (indexLanguage < 0) {
                this.description.push({
                  lang: this.language[index].lang, // th , en
                  value: ""
                });
              }
            }
          }
        } else {
          const {
            _id,
            name,
            description,
            background,
            typeTemplate,
            typeLink,
            hideTitle,
            link
          } = this.data;
          this.dataId = _id;
          this.background = background;
          this.typeTemplate = typeTemplate;
          this.typeLink = typeLink;
          this.hideTitle = hideTitle;
          this.link = link;
          this.name = name;
          if (this.name.length !== this.language.length) {
            for (let index = 0; index < this.language.length; index += 1) {
              const indexLanguage = this.name.findIndex(
                element => element.lang === this.language[index].lang
              );
              if (indexLanguage < 0) {
                this.name.push({
                  lang: this.language[index].lang, // th , en
                  value: ""
                });
              }
            }
            for (let index = 0; index < this.name.length; index += 1) {
              const indexLanguage = this.language.findIndex(
                element => element.lang === this.name[index].lang
              );
              if (indexLanguage < 0) {
                this.name.splice(index, 1);
              }
            }
          }
          this.description = description;
          if (this.description.length !== this.language.length) {
            for (let index = 0; index < this.language.length; index += 1) {
              const indexLanguage = this.description.findIndex(
                element => element.lang === this.language[index].lang
              );
              if (indexLanguage < 0) {
                this.description.push({
                  lang: this.language[index].lang, // th , en
                  value: ""
                });
              }
            }
            for (let index = 0; index < this.description.length; index += 1) {
              const indexLanguage = this.language.findIndex(
                element => element.lang === this.description[index].lang
              );
              if (indexLanguage < 0) {
                this.description.splice(index, 1);
              }
            }
          }
        }
      }
    }
  },
  methods: {
    viewImage(image) {
      this.showImage = true
      this.imageView = image
    },
    initValues() {
      this.dataId = null;
      this.background = "";
      this.name = [];
      this.description = [];
      this.typeTemplate = "";
      this.typeLink = "";
      this.hideTitle = "";
      this.link = "";
    },
    inputLanguage(input, lang, index, type) {
      if (type === "name") {
        if (this.name[index].lang === lang) {
          this.name[index].value = input;
        }
      } else if (type === "description") {
        if (this.description[index].lang === lang) {
          this.description[index].value = input;
        }
      }
    },
    validationForm() {
      this.$refs.formData.validate().then(success => {
        if (success) {
          const obj = {
            _id: this.dataId,
            background: this.background,
            name: this.name,
            description: this.description,
            typeTemplate: this.typeTemplate,
            typeLink: this.typeLink,
            hideTitle: this.hideTitle,
            link: this.link
          };
          this.$emit("update:show", true);
          if (this.dataId !== null) {
            store
              .dispatch(`${this.storeModuleName}/update`, obj)
              .then(result => {
                this.$emit("update:is-sidebar-active", false);
                this.$emit("update:show", false);

                // this.showToast('success', 'bottom-right')
              })
              .catch(error => {
                console.log("fetchUsers Error : ", error);
                this.$toast({
                  component: ToastificationContent,
                  position: "bottom-right",
                  props: {
                    title: this.$t("Error"),
                    icon: "ErrorIcon",
                    variant: "danger",
                    text: this.$t(error.response.data.message)
                  }
                });
                this.$emit("update:show", false);
                // this.showToast('danger', 'bottom-right')
              });
          }
        }
      });
    },
    showFromCurrentLanguage(data) {
      if (data) {
        const indexLang = data.findIndex(e => e.lang === this.$i18n.locale);
        if (indexLang > -1) {
          return data[indexLang].value;
        }
        return "";
      }
      return "";
    }
  }
};
</script>
  
  <style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
  